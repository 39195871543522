export const validateRate = (rate: number | null): Error | null => {
  if (rate === null) {
    return null;
  }

  if (rate <= 0) {
    return Error('Enter a positive number');
  }

  return null;
};
