import { useMemo } from 'react';

import { baseUrl } from 'config';
import { useFetchContracts } from 'api';
import { Candidate } from 'types';

interface BuildCvLinkOptions {
  candidate: Candidate;
}

export const useCvLink = ({ candidate }: BuildCvLinkOptions) => {
  const { data: contracts } = useFetchContracts(candidate.lemonId);

  const rate = candidate.max_rate_per_hour ? candidate.max_rate_per_hour + 30 : '';

  const projectsParamForCv = useMemo(() => [...(contracts?.contracts ?? [])].length || '', [contracts]);

  const darkCvLink = `${baseUrl}cv/${candidate.id}/?rate=${rate}&projects=${projectsParamForCv}`;
  const lightCvLink = `${darkCvLink}&light=true`;

  return { lightCvLink, darkCvLink };
};
