import React, { memo, useMemo, useCallback, useState } from 'react';
import { Descendant } from 'slate';

import { MdEditorTemplate, EditorTemplate } from 'types';

import BaseEditor, { Props as BaseEditorProps } from './base-editor';
import { serialize, parse } from './md-parser';

type Props = Omit<BaseEditorProps, 'initialValue' | 'onChange' | 'onSubmit' | 'templates'> & {
  initialValue?: string;
  onChange?: (value: string) => void;
  onSubmit?: (value: string) => void;
  templates?: Array<MdEditorTemplate>;
  dataTestId?: string;
  autoFocus?: boolean;
  helperText?: string;
};

const Editor: React.FC<Props> = ({
  initialValue = '',
  onChange = () => null,
  onSubmit = () => null,
  templates,
  dataTestId,
  autoFocus,
  ...rest
}) => {
  const [version, setVersion] = useState(1);

  const editorInitialValue = useMemo(() => parse(initialValue), [initialValue]);

  const editorTempaltes = useMemo<Array<EditorTemplate> | undefined>(() => {
    if (!templates?.length) {
      return undefined;
    }

    return templates.map<EditorTemplate>((template) => ({ ...template, content: parse(template.content) }));
  }, [templates]);

  const handleChange = useCallback(
    (value: Array<Descendant>) => {
      const md = serialize(value);
      onChange(md);
    },
    [onChange]
  );

  const handleSubmit = useCallback(
    (value: Array<Descendant>) => {
      const md = serialize(value);
      onSubmit(md);

      /**
       * For rerendering on submit
       * to ensure sync of base editor state
       */
      if (initialValue === md) {
        setVersion((v) => v + 1);
      }
    },
    [initialValue, onSubmit]
  );

  return (
    <BaseEditor
      key={version}
      initialValue={editorInitialValue}
      onChange={handleChange}
      onSubmit={handleSubmit}
      templates={editorTempaltes}
      dataTestId={dataTestId}
      autoFocus={autoFocus}
      {...rest}
    />
  );
};

export default memo(Editor);
