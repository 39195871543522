import React, { memo, ReactElement, RefObject, useState } from 'react';
import { DatePicker, type DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { SxProps, TextField, Button, Box } from '@mui/material';

interface OnChangeOptions {
  isOngoing: boolean;
}

export interface Props {
  className?: string;
  sx?: SxProps;
  value?: Date | null;
  onChange?: (value: Date | null, options: OnChangeOptions) => void;
  label: string;
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  onFocus?: () => void;
  onBlur?: () => void;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
  error?: boolean | string;
  views?: DatePickerProps<unknown, unknown>['views'];
  enableClear?: boolean;
  enableOngoing?: boolean;
  isOngoing?: boolean;
  minDate?: Date;
  maxDate?: Date;
  inputFormat?: string;
  placeholder?: string;
  inputRef?: RefObject<HTMLInputElement>;
  helperText?: string;
  dataTestId?: string;
}

const FormDateInput = ({
  className,
  sx = {},
  value = null,
  onChange = () => null,
  label,
  color = 'info',
  onFocus,
  onBlur = () => null,
  onKeyUp = () => null,
  error,
  views,
  enableClear = false,
  enableOngoing = false,
  isOngoing = false,
  minDate,
  maxDate,
  inputFormat = 'MMM dd, yyyy',
  placeholder,
  inputRef,
  helperText,
  dataTestId,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <DatePicker
      inputFormat={inputFormat}
      value={value}
      onChange={(value: Date | null) => {
        onChange(value, { isOngoing: false });
      }}
      views={views}
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      {...(enableClear &&
        value && {
          components: {
            ActionBar: () => (
              <Box sx={{ padding: '0 16px 16px' }}>
                <Button
                  onClick={() => {
                    onChange(null, { isOngoing: false });
                  }}
                >
                  Clear
                </Button>
              </Box>
            ),
          },
        })}
      {...(enableOngoing &&
        (value || !isOngoing) && {
          components: {
            ActionBar: () => (
              <Box sx={{ padding: '0 16px 16px' }}>
                <Button
                  onClick={() => {
                    onChange(null, { isOngoing: true });
                  }}
                >
                  Ongoing
                </Button>
              </Box>
            ),
          },
        })}
      {...(enableOngoing && value === null && { InputProps: { inputProps: { value: isOngoing ? 'Ongoing' : '' } } })}
      minDate={minDate}
      maxDate={maxDate}
      renderInput={(params) => (
        <TextField
          {...params}
          ref={inputRef}
          autoComplete="none"
          onClick={() => setIsOpen(true)}
          placeholder={placeholder}
          className={className}
          sx={[
            (theme) => ({
              '.MuiInput-root:not(.Mui-error)::after': {
                borderColor: theme.palette.primary.main,
              },
            }),
            ...(Array.isArray(sx) ? sx : [sx]),
          ]}
          variant="standard"
          fullWidth
          onKeyDown={(e) => e.preventDefault()}
          label={label}
          color={color}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyUp={onKeyUp}
          error={!!error}
          {...(typeof error === 'string' || typeof helperText === 'string'
            ? { helperText: !error || typeof error === 'boolean' ? helperText : error }
            : null)}
          {...(dataTestId && { 'data-testid': `${dataTestId}__wrapper` })}
          inputProps={{
            ...params.inputProps,
            placeholder,
            'data-testid': dataTestId,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: params?.InputProps?.endAdornment
              ? React.cloneElement(params?.InputProps?.endAdornment as ReactElement, {
                  ...(dataTestId && { 'data-testid': `${dataTestId}__end-adornment` }),
                })
              : null,
          }}
        />
      )}
    />
  );
};

export default memo(FormDateInput);
