import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import UpdateIcon from '@mui/icons-material/Update';

import { DiffActionType, DiffAuthorType, TagLogActionType } from 'types';

export const DIFF_ICON_MAP: Record<string, React.ReactNode> = {
  [DiffActionType.Create]: <CheckCircleOutlineIcon />,
  [DiffActionType.Update]: <UpdateIcon />,
  [DiffActionType.Delete]: <DeleteForeverIcon />,
  [TagLogActionType.ContactTagAssigned]: <CheckCircleOutlineIcon />,
  [TagLogActionType.ContactTagUnassigned]: <DeleteForeverIcon />,
  [TagLogActionType.ContactProjectTagAssigned]: <CheckCircleOutlineIcon />,
  [TagLogActionType.ContactProjectTagUnassigned]: <DeleteForeverIcon />,
};

export const DIFF_TARGET_MAP: Record<string, string> = {
  min_rate_per_hour: 'Rate min',
  max_rate_per_hour: 'Rate max',
  rateInfo: 'Rate info',
  availability_type: 'Availability type',
  available_hours_per_week: 'Available hours',
  potentiallyAvailableSince: 'Potentially available since',
  mainSkills: 'Main skills',
  otherSkills: 'Other skills',
  core_technologies: 'Main skills',
  additional_technologies: 'Other skills',
  skill: 'Skill',
  specializations: 'Specializations',
  specialization: 'Specialization',
  seniority: 'Self-assessment',
  tag: 'Tag',
};

export const DIFF_ACTION_TYPE_MAP: Record<string, React.ReactNode> = {
  [DiffActionType.Create]: 'Created',
  [DiffActionType.Update]: 'Updated',
  [DiffActionType.Delete]: 'Deleted',
  [TagLogActionType.ContactTagAssigned]: 'assigned',
  [TagLogActionType.ContactTagUnassigned]: 'unassigned',
  [TagLogActionType.ContactProjectTagAssigned]: 'assigned to project',
  [TagLogActionType.ContactProjectTagUnassigned]: 'unassigned from project',
};

export const AUTHOR_TYPE_MAP: Record<string, string> = {
  [DiffAuthorType.User]: 'CRM',
  [DiffAuthorType.Contact]: 'Me.lemon',
};

export const DIFF_TARGETS_WITH_WORD_DIFF = ['mainSkills', 'otherSkills', 'specializations'];
