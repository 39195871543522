import { AvailabilityType, Candidate } from './candidate';

export enum ProjectMatchType {
  PREMATCH = 'Prematch',
  NEW = 'New',
  REMATCH = 'Rematch',
}

export enum ProjectInvolvementType {
  PART_TIME_ONLY = 'PartTimeOnly',
  FULL_TIME_ONLY = 'FullTimeOnly',
  BOTH_PART_AND_FULL_TIME = 'BothPartAndFullTime',
  PART_TIME_CAN_SWITCH_TO_FULL_TIME = 'PartTimeCanSwitchToFullTime',
  UNKNOWN = 'Unknown',
  UNAVAILABLE = 'Unavailable',
}

export enum ProjectWeeklyInvolvement {
  LESS_THAN_20H = '<20h',
  '20H' = '20h',
  '25H' = '25h',
  '30H' = '30h',
  '35H' = '35h',
  '40H' = '40h',
  UNKNOWN = 'Unknown',
}

export enum ProjectDealStage {
  OfferMade = 'OfferMade',
  MatchPlease = 'MatchPlease',
  MatchReady = 'MatchReady',
  ClosedLost = 'ClosedLost',
  ClosedWon = 'ClosedWon',
  Qualification = 'Qualification',
  Negotiation = 'Negotiation',
  OutForSignature = 'OutForSignature',
  NewRequests = 'NewRequests',
}

export interface HubspotProject {
  id: string;
  dealId: string;
  dealName: string;
  dealStage: ProjectDealStage;
  dealDescription: string;
  dealJobPost: string;
  dealDevTeamSize: string;
  dealBudget: string;
  dealOwner: string;
  dealCSManager: string;
  dealSalesToMatchingNotes: string;
  dealTeamSize: string;
  dealTeamCommunication: string;
  dealExpectedEngagementDuration: string;
  dealPreferredStartDate: string;
  dealRequiredOverlap: string;
  dealClientTimeZone: string;
  dealProjectTechStack: string;
  dealSelectionProcess: string;
  dealLevelOfInvolvement: string;
  dealMustHaveSkills: Array<string>;
  dealNiceToHaveSkills: Array<string>;
  dealSeniority: Array<string>;
  dealSpecialization: string;
  dealAssigneeId: string;
  dealMatchingType: string;
  matchType: ProjectMatchType;
  dealRematchReasons: Array<string>;
  dealCsRematchReasons: Array<string>;
  dealCandidatesApproved: string;
  dealCandidatesDeclined: string;
  dealPriority: 'low' | 'medium' | 'high';
  assignee: Array<string>;
  dealMatchingOutcome: 'Matched' | 'Not matched';
  dealNoMatchReasons: Array<string>;
  dealNoMatchComment: string | null;
  projectName: string;
  projectDescription: string;
  projectTeamSize: string;
  projectTeamCommunication: string;
  projectDevTeamSize: string;
  projectExpectedEngagementDuration: string;
  projectPreferredStartDate: string;
  projectRequiredOverlap: string;
  projectClientTimeZone: string;
  projectProjectTechStack: string;
  projectSelectionProcess: string;
  projectRateMain: number | null;
  projectRateMax: number | null;
  projectInvolvementType: ProjectInvolvementType | null;
  projectWeeklyInvolvement: ProjectWeeklyInvolvement | null;
  projectMustHaveSkills: HubspotProjectSkill[];
  projectNiceToHaveSkills: HubspotProjectSkill[];
  projectSeniority: string;
  projectSpecialization: string;
  projectAssigneeId: string;
  projectMatchingType: string;
  dateProjectCreated: string;
  dateProjectUpdated: string;
  dateProjectReopen: string;
  dateProjectCreatedOrLastReopen: string;
  dateDealCreated: string;
  dateDealUpdated: string;
  dateRematched: string;
  projectPublishStatus: 'Published' | 'Unpublished';
  projectMatcherNote: string;
  projectMustBeAvailableForDirectHire: boolean | null;
  dealPrimaryCompanyId: string | null;
  dealPrimaryCompanyName: string | null;
  dealPrimaryCompanyDomainName: string | null;
  dealRedFlags: Array<string> | null;
  dealJiminnyLinks: Array<string> | null;
  companyUsedInManyProjects?: boolean;
  beenOnMatchPlease?: boolean;
}

export interface HubspotProjectSkill {
  id: string;
  createdDate: string;
  hubspotProjectID: string;
  name: string;
  years: number | null;
  main: boolean | null;
}

export interface HubspotProjectSpecialization {
  id: string;
  createdDate: string;
  hubspotProjectID: string;
  title: string | null;
  seniority: string | null;
  has_custom_title: boolean | null;
}

export interface HubspotProjectCandidate {
  id: string;
  contactId: string;
  hubspotProjectId: string;
  createdAt: string;
  contact: Candidate;
  hubspotProject: HubspotProject;
  availabilityType: AvailabilityType;
  candidateAvailableHoursPerWeek?: ProjectWeeklyInvolvement | null;
  clientRatePerHour: number | null;
  candidateRatePerHour: number | null;
  rateCommentForDev: string | null;
  salesComment: string | null;
  summary: string | null;
  interviewTimeslots: string | null;
  readyToStartAt: string | null;
  vacationPlans?: string;
  workingHoursFromInClientTimeZone?: string;
  workingHoursToInClientTimeZone?: string;
  clientTimeZoneShortened?: string;
  candidateApplicationComment?: string;
  isCandidateApplicationCommentHidden?: boolean;
  slackURL: string | null;
  profileLogNote: string | null;
  declinedAt: string;
  declineReasons: Array<string>;
  declineFeedback: string | null;
  declineStage: string;
  approvedAt: string;
  isForClientReview: boolean;
  isHidden?: boolean;
  matcherId: string | null;
  customerSuccessManagerId: string | null;
  isAutoMatched?: boolean;
  isManuallyMatched?: boolean;
  wasAutoMatched?: boolean;
  isLikedByClient?: boolean;
  wasLikedByClient?: boolean;
  projectQuestions?: HubspotProjectQuestion[];
  projectAnswers?: HubspotProjectAnswer[];
  hasCandidateConfirmedApplication?: boolean;
}

export enum HubspotProjectCandidateDeclineStage {
  MATCHING = 'Matching',
  SALES = 'Sales',
  CLIENT_SUCCESS = 'Client Success',
}

export enum MatchingStageDeclineReason {
  NO_RESPONSE = 'No response',
  RATE_MISMATCH = 'Rate mismatch',
  PROFILE_MISMATCH = 'Profile mismatch',
  TEMPORARY_UNAVAILABLE = 'Temporary unavailable',
  CLIENT_REFUSED_TO_CONTINUE = 'Client refused to continue',
  DEV_DECLINED_PROJECT = 'Dev declined project',
}

export enum SalesStageDeclineReason {
  WEAK_CANDIDATE = 'Weak candidate',
  BUDGET = 'Budget',
  DEVS_VACATION_SICK_LEAVE = "Dev's vacation/sick leave",
  DEV_ACCEPTED_ANOTHER_OFFER = 'Dev accepted another offer',
  DEV_REFUSED_TO_CONTINUE = 'Dev refused to continue',
  CLIENT_WANTS_TO_SEE_MORE_OPTIONS = 'Client wants to see more options',
  CLIENT_CHANGED_REQUIREMENTS = 'Client changed requirements',
  CLIENT_REFUSED_TO_CONTINUE = 'Client refused to continue',
  WORKLOAD_MISMATCH = 'Workload mismatch',
}

export enum CSStageDeclineReason {
  ORGANIC_CHURN = 'Organic churn',
  OPT_OUT = 'Opt-out',
  DEV_LEFT = 'Dev left',
  POOR_PERFORMANCE = 'Poor performance',
  BUDGET = 'Budget',
  IN_HOUSE_HIRE = 'In-house hire',
  BAD_QUALIFICATION = 'Bad qualification',
  MISMATCHED_EXPECTATIONS = 'Mismatched expectations',
  DEV_LEFT_OK = 'Dev left OK',
  BUDGET_BY_CS = 'Budget (by CS)',
}

export interface HubspotProjectCompany {
  id: string;
  name: string;
}

export enum HubspotProjectQuestionAuthorType {
  Client = 'Client',
  Operator = 'Operator',
}

export interface HubspotProjectQuestionBase {
  hubspotProjectId?: string;
  title?: string;
  isHidden?: boolean;
}
export interface HubspotProjectQuestion extends HubspotProjectQuestionBase {
  id?: string;
  authorId?: string;
  authorType?: HubspotProjectQuestionAuthorType;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface HubspotProjectAnswer {
  id?: string;
  hubspotProjectQuestionId?: string;
  hubspotProjectContactId?: string;
  text?: string;
  isHidden?: boolean;
  originalQuestionTitle?: string;
  createdAt?: Date;
  updatedAt?: Date;
}
