import { format, parseISO } from 'date-fns';

import { Specialization } from 'features/specializations/types';
import { CandidateSkill, CandidateProfileDiff, DiffItemType, CandidateSpecialization, TagLog } from 'types';

import { DiffsGroupObject, DiffObject, DiffsGroupedByRelatedId } from './types';
import { AUTHOR_TYPE_MAP } from './diff-group.constants';

import { Operator } from 'types';

const EMPTY_OBJECT_JSON = '{}';

const getLastUpdatedDiff = (diffs: Array<DiffObject>) => {
  if (diffs.length === 0) {
    return null;
  }

  return diffs.reduce((latestDiff, currentDiff) => {
    const latestDate = parseISO(latestDiff.dateCreated);
    const currentDate = parseISO(currentDiff.dateCreated);

    return latestDate > currentDate ? latestDiff : currentDiff;
  });
};

const createDiffGroupObject = (groupName: string, diffs: Array<DiffObject>): DiffsGroupObject => {
  const lastUpdatedDiff = getLastUpdatedDiff(diffs);

  return {
    groupName,
    lastUpdatedDate: lastUpdatedDiff?.dateCreated ?? null,
    lastUpdatedAuthor: lastUpdatedDiff?.author ?? null,
    lastUpdatedAuthorId: lastUpdatedDiff?.authorId ?? null,
    diffs: groupDiffsByRelatedId(diffs),
  };
};

const createDiffObject = <T>(
  diff: CandidateProfileDiff,
  property: string,
  mapValue?: (value: T) => Array<string>,
  relatedIds?: (previousPropertyValue: T, newPropertyValue: T) => Array<string>
): DiffObject => {
  const parsedPreviousValue = JSON.parse(diff.previousValue);
  const previousPropertyValue = parsedPreviousValue[property];

  const parsedNewValue = JSON.parse(diff.newValue);
  const newPropertyValue = parsedNewValue[property];

  const diffObject: DiffObject = {
    previousValue: mapValue ? mapValue(previousPropertyValue) : [previousPropertyValue || ''],
    newValue: mapValue ? mapValue(newPropertyValue) : [newPropertyValue || ''],
    target: property,
    actionType: diff.actionType,
    author: diff.authorType,
    authorId: diff.authorId ?? null,
    dateCreated: diff.dateCreated,
    relatedIds: relatedIds ? relatedIds(previousPropertyValue, newPropertyValue) : [diff.itemId],
  };

  return diffObject;
};

const checkDiffProperty = (diff: CandidateProfileDiff, property: string) => {
  const parsedNewValue = JSON.parse(diff.newValue);
  return parsedNewValue[property] !== undefined;
};

const sortDiffs = (diffs: Array<DiffObject>) =>
  [...diffs].sort((a, b) => +parseISO(b.dateCreated) - +parseISO(a.dateCreated));

const sortGroupedDiffs = (groupedDiffs: Array<DiffsGroupedByRelatedId>): Array<DiffsGroupedByRelatedId> =>
  [...groupedDiffs].sort((a, b) => +parseISO(b.diffs[0].dateCreated) - +parseISO(a.diffs[0].dateCreated));

const groupDiffsByRelatedId = (diffs: Array<DiffObject>): Array<DiffsGroupedByRelatedId> => {
  const groupMap: Record<string, Array<DiffObject>> = {};

  for (const diff of diffs) {
    for (const relatedId of diff.relatedIds) {
      if (!groupMap[relatedId]) {
        groupMap[relatedId] = [diff];
        continue;
      }

      groupMap[relatedId].push(diff);
    }
  }

  const groupedDiffs = Object.keys(groupMap).map((relatedId) => ({
    relatedId,
    diffs: sortDiffs(groupMap[relatedId]),
  }));

  return sortGroupedDiffs(groupedDiffs);
};

const getShowInCvLabel = (showInCv: boolean | undefined | null) => {
  if (typeof showInCv !== 'boolean') {
    return '';
  }

  return showInCv ? 'Show in CV' : 'Not show in CV';
};

export const prepareDiffs = (
  diffs: Array<CandidateProfileDiff>,
  skills: Array<CandidateSkill>,
  specializations: Array<CandidateSpecialization>,
  tagLogs: Array<TagLog>
): Array<DiffsGroupObject> => {
  const diffsGroups: Array<DiffsGroupObject> = [];

  const contactDiffs = diffs.filter((diff) => diff.itemType === DiffItemType.Contact);
  const skillDiffs = diffs.filter((diff) => diff.itemType === DiffItemType.Skill);
  const specializationDiffs = diffs.filter((diff) => diff.itemType === DiffItemType.Specialization);

  const getSkillById = (id: string) => skills.find((s) => s.id === id);
  const getSpecializationById = (id: string) => specializations.find((s) => s.id === id);

  // RATES

  const rateMinDiffs = contactDiffs
    .filter((diff) => checkDiffProperty(diff, 'min_rate_per_hour'))
    .map((diff) => createDiffObject(diff, 'min_rate_per_hour'));

  const rateMaxDiffs = contactDiffs
    .filter((diff) => checkDiffProperty(diff, 'max_rate_per_hour'))
    .map((diff) => createDiffObject(diff, 'max_rate_per_hour'));

  const rateInfoDiffs = contactDiffs
    .filter((diff) => checkDiffProperty(diff, 'rateInfo'))
    .map((diff) => createDiffObject(diff, 'rateInfo'));

  const ratesDiffs: Array<DiffObject> = [...rateMinDiffs, ...rateMaxDiffs, ...rateInfoDiffs];

  const ratesDiffGroup = createDiffGroupObject('Rates', ratesDiffs);

  diffsGroups.push(ratesDiffGroup);

  // AVAILABILITY

  const availabilityTypeDiffs = contactDiffs
    .filter((diff) => checkDiffProperty(diff, 'availability_type'))
    .map((diff) => createDiffObject(diff, 'availability_type'));

  const availableHoursDiffs = contactDiffs
    .filter((diff) => checkDiffProperty(diff, 'available_hours_per_week'))
    .map((diff) => createDiffObject(diff, 'available_hours_per_week'));

  const potentiallyAvailableSinceDiffs = contactDiffs
    .filter((diff) => checkDiffProperty(diff, 'potentiallyAvailableSince'))
    .map((diff) => createDiffObject(diff, 'potentiallyAvailableSince'));

  const availabilityDiffs: Array<DiffObject> = [
    ...availabilityTypeDiffs,
    ...availableHoursDiffs,
    ...potentiallyAvailableSinceDiffs,
  ];

  const availabilityDiffGroup = createDiffGroupObject('Availability', availabilityDiffs);

  diffsGroups.push(availabilityDiffGroup);

  // TAGS

  const tagsDiffs = tagLogs.map((tagLog) => {
    const diffObject: DiffObject = {
      previousValue: [],
      newValue: [],
      target: 'tag',
      targetName: tagLog.tagName ?? '',
      relatedIds: [tagLog.contactId as string],
      actionType: tagLog.actionType,
      author: tagLog.authorType,
      authorId: tagLog.authorId ?? null,
      dateCreated: tagLog.createdDate,
    };

    return diffObject;
  });

  const tagsDiffGroup = createDiffGroupObject('Tags', tagsDiffs);

  diffsGroups.push(tagsDiffGroup);

  // TECH STACK

  const mapCandidateSkill = ({ name, years, show_in_cv }: Partial<CandidateSkill>) =>
    [name, years ? `${years} year${Number(years) !== 1 ? 's' : ''}` : '', getShowInCvLabel(show_in_cv)]
      .filter(Boolean)
      .join(' - ');

  const skillItemDiffs = skillDiffs.map((diff) => {
    const parsedPreviousValue: Partial<CandidateSkill> = JSON.parse(diff.previousValue ?? EMPTY_OBJECT_JSON);
    const parsedNewValue: Partial<CandidateSkill> = JSON.parse(diff.newValue ?? EMPTY_OBJECT_JSON);

    // TOOD: Decide whether it's needed
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const skillItem = getSkillById(diff.itemId);

    const diffObject: DiffObject = {
      previousValue: [mapCandidateSkill(parsedPreviousValue)],
      newValue: [mapCandidateSkill(parsedNewValue)],
      target: 'skill',
      relatedIds: [diff.itemId],
      actionType: diff.actionType,
      author: diff.authorType,
      authorId: diff.authorId ?? null,
      dateCreated: diff.dateCreated,
    };

    return diffObject;
  });

  const techStackDiffs: Array<DiffObject> = [...skillItemDiffs];

  const techStackDiffGroup = createDiffGroupObject('Tech stack', techStackDiffs);

  diffsGroups.push(techStackDiffGroup);

  // SPECIALIZATIONS

  const specializationItemDiffs = specializationDiffs.map((diff) => {
    const parsedPreviousValue: Partial<Specialization> = JSON.parse(diff.previousValue ?? EMPTY_OBJECT_JSON);
    const parsedNewValue: Partial<Specialization> = JSON.parse(diff.newValue ?? EMPTY_OBJECT_JSON);

    // TOOD: Decide whether it's needed
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const specializationItem = getSpecializationById(diff.itemId);

    const mapValue = ({ seniority, title, show_in_cv }: Partial<Specialization>) =>
      [[seniority, title].filter(Boolean).join(' '), getShowInCvLabel(show_in_cv)].filter(Boolean).join(' - ');

    const diffObject: DiffObject = {
      previousValue: [mapValue(parsedPreviousValue)],
      newValue: [mapValue(parsedNewValue)],
      target: 'specialization',
      relatedIds: [diff.itemId],
      actionType: diff.actionType,
      author: diff.authorType,
      authorId: diff.authorId ?? null,
      dateCreated: diff.dateCreated,
    };

    return diffObject;
  });

  const specializationsDiffs: Array<DiffObject> = [...specializationItemDiffs];

  const specializationsDiffGroup = createDiffGroupObject('Specializations', specializationsDiffs);

  diffsGroups.push(specializationsDiffGroup);

  // SELF-ASSESSMENT

  const selfAssessmentDiffs = contactDiffs
    .filter((diff) => checkDiffProperty(diff, 'seniority'))
    .map((diff) => createDiffObject(diff, 'seniority'));

  const selfAssessmentDiffGroup = createDiffGroupObject('Self-assessment', selfAssessmentDiffs);

  diffsGroups.push(selfAssessmentDiffGroup);

  return diffsGroups;
};

export const getLastUpdatedText = (
  { lastUpdatedDate, lastUpdatedAuthor }: Pick<DiffsGroupObject, 'lastUpdatedDate' | 'lastUpdatedAuthor'>,
  dateFormat = 'MMM dd, yyyy HH:mm'
) => {
  const lastUpdatedDateISO = parseISO(lastUpdatedDate!);
  const formattedDate = format(lastUpdatedDateISO, dateFormat);

  const getLastUpdatedAuthorText = () => {
    if (!lastUpdatedAuthor) return null;
    const changeSource = AUTHOR_TYPE_MAP[lastUpdatedAuthor];
    return changeSource ? `via ${changeSource}` : `by ${lastUpdatedAuthor}`;
  };

  return [`Last updated: ${formattedDate}`, getLastUpdatedAuthorText()].join(' ');
};

export const getAuthorName = (operators: Operator[], authorId: string) => {
  const foundOperator = operators.find((operator) => operator.id === authorId);
  return foundOperator?.name ?? '';
};

export const getLastUpdatedTextForCandidateProperty = (
  diffs: Array<CandidateProfileDiff>,
  property: string,
  dateFormat = 'MMM dd, yyyy',
  operators?: Operator[]
): string | undefined => {
  const contactDiffs = diffs.filter((diff) => diff.itemType === DiffItemType.Contact);

  const propertyDiffs = contactDiffs
    .filter((diff) => checkDiffProperty(diff, property))
    .map((diff) => createDiffObject(diff, property));

  const lastUpdatedDiff = getLastUpdatedDiff(propertyDiffs);

  const lastUpdatedAuthorName =
    operators?.length && lastUpdatedDiff?.authorId ? getAuthorName(operators, lastUpdatedDiff.authorId) : null;

  return lastUpdatedDiff?.dateCreated
    ? getLastUpdatedText(
        {
          lastUpdatedDate: lastUpdatedDiff?.dateCreated,
          lastUpdatedAuthor: lastUpdatedAuthorName || lastUpdatedDiff?.author,
        },
        dateFormat
      )
    : undefined;
};
