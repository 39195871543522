import { Editable } from 'slate-react';
import { styled } from '@mui/system';
import { Box } from '@mui/material';

import { FormInput } from '../../text-inputs';

export const EditorField = styled(Box)(() => ({
  position: 'relative',
  paddingTop: '0.01px',
}));

interface StyledEditableProps {
  heightRestriction: boolean;
  gutterBottom?: boolean;
}

export const StyledEditable = styled(Editable)<StyledEditableProps>(
  ({ readOnly, heightRestriction, gutterBottom }) => ({
    position: 'relative',
    zIndex: 5,

    ...(heightRestriction && {
      maxHeight: '200px',
      overflow: 'auto',
    }),

    ...(!readOnly && {
      paddingTop: '4px',
      paddingBottom: '5px',
      marginTop: '16px',
    }),

    ...(gutterBottom && {
      marginBottom: '24px',
    }),

    '& *[data-slate-node="element"]': {
      margin: '0',
    },

    '& ul, & ol': {
      paddingLeft: '28px',
    },
  })
);

export const StyledFormInput = styled(FormInput)(({ helperText }) => ({
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',

  '& .MuiInput-root': {
    height: '100%',
    pointerEvents: 'none',

    ...(Boolean(helperText) && {
      marginBottom: '24px',
    }),
  },

  ...(Boolean(helperText) && {
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: '0px',
    },
  }),
}));
